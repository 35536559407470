@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
@import 'typography';

.container {
  background-color: $grey-100;
  padding: 0 1.5rem 3rem;

  @include screen-size('medium') {
    padding: 4rem 0;
  }
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0;
  max-width: 1320px;

  @include screen-size('medium') {
    flex-direction: row;
    margin: 0 1rem;
  }

  @include screen-size('custom', 1352px) {
    margin: 0 auto;
  }
}

.column {
  display: flex;
  margin: 0;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 100%;

  @include screen-size('medium') {
    max-height: 35rem;
  }
}

.heading {
  h2 {
    @include display-lg;
    @include text-bold;
    @include font-primary;

    color: $grey-900;
    margin: 0;

    @include screen-size('medium') {
      max-width: 85%;
    }

    @include screen-size('custom', 1352px) {
      max-width: 75%;
    }
  }
}

.text {
  p {
    @include text-lg;
    @include text-normal;
    @include font-primary;

    color: $grey-800;

    &:first-of-type {
      margin-bottom: 1.35rem;
    }

    span {
      color: $grey-900;
    }
  }
}
